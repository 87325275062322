import $ from 'jquery';
import intlTelInput from "intl-tel-input";
import globalJS from './global.js'
import './validations';

window.$ = $;
window.jQuery = $;

$(document).ready(function () {
    // globalJS.handleAuthSuccess();
    getIpInfo();
});

$(document).on('click', '.loggged-wrapper', function () {
    if($(this).find('.logged-submenu').toggleClass('hide'));
});

function getIpInfo() {
    $.ajax({
        url: appUrl + '/ip/details',
        method: "GET",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'ip': requestIp
        },
        success: function (data) {
            // console.log(data);
            let responseData = data.response.data
            console.log(responseData);
            let ip = responseData.country_code ? responseData.country_code.toLowerCase() : null;

            let countryCodeFromIp = 'ae'
            if(ip) {
                countryCodeFromIp = ip;
            }

            initIntl(countryCodeFromIp);
        },
        error: function (err) {
            let countryCodeFromIp = 'ae';
            initIntl(countryCodeFromIp);
        }
    });
}

function initIntl(initialCountryCode) {
    const countryCodeInput = document.querySelector(".countryCode");
    if(countryCodeInput) {
        intlTelInput(countryCodeInput, {
            initialCountry: initialCountryCode || 'ae',
            separateDialCode: true,
            preferredCountries: ['ae', 'sa', 'kw', 'qa', 'bh', 'om', 'in']
        });
    }

    const countryCodeFormBasedInput = document.querySelector(".countryCodeFormBased");
    if(countryCodeFormBasedInput) {
        intlTelInput(countryCodeFormBasedInput, {
            initialCountry: initialCountryCode || 'ae',
            separateDialCode: true,
            preferredCountries: ['ae', 'sa', 'kw', 'qa', 'bh', 'om', 'in']
        });
    }
}

window.$(document).on('click', '.signup-form-btn', function (e) {
    // console.log('clicked');
    e.preventDefault();
    signup();
});

window.$(document).on('click', '.js-login-submit', function (e) {
    e.preventDefault();
    console.log('awdawd');
    login();
});

function signup() {
    let signupForm = $('#signup-form');
    // console.log('here');
    let btn = signupForm.find('#signup-form-btn');

    let url = signupForm.attr('action');

    if (!signupForm.valid()) {
        return;
    }

    let countryCode = signupForm.find('.iti__selected-dial-code').text();
    // console.log(countryCode);
    if (countryCode.charAt(0) === '+') {
        countryCode = countryCode.substr(1);
    }
    let form = $(signupForm)[0];
    let formData = new FormData(form);
    formData.append('calling_code', countryCode);

    let signupTerms = $('#signupTermsCondition');
    console.log(signupTerms.prop('checked'));
    if(!signupTerms.prop('checked')) {
        signupTerms.addClass('error-outline');
        return ;
    }
    else {
        signupTerms.removeClass('error-outline');
    }

    globalJS.gaSubmitEvent('SIGNUP_INITIATED');
    globalJS.fbSubmitEvent('SIGNUP_INITIATED');
    globalJS.startBtnLoader(btn);

    $.ajax({
        url: url,
        method: "POST",
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: formData,
        success: function (data) {
            globalJS.handleAuthSuccess('/');
            globalJS.stopBtnLoader(btn);
            globalJS.gaSubmitEvent('SIGNUP_COMPLETED');
            globalJS.fbSubmitEvent('SIGNUP_COMPLETED');
        },
        error: function (err) {
            globalJS.displayError(err);
            globalJS.stopBtnLoader(btn);
        }
    });
}

function login() {
    let loginForm = $('#modal-login-form');

    console.log('login here');
    let btn = loginForm.find('#signup-form-btn');

    let url = loginForm.attr('action');

    if (!loginForm.valid()) {
        console.log('not valid');
        return;
    }

    let form = $(loginForm)[0];
    let formData = new FormData(form);

    globalJS.gaSubmitEvent('LOGIN_INITIATED');
    globalJS.fbSubmitEvent('LOGIN_INITIATED');
    globalJS.startBtnLoader(btn);

    $.ajax({
        url: url,
        method: "POST",
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: formData,
        success: function (data) {
            globalJS.handleAuthSuccess('/');
            globalJS.stopBtnLoader(btn);
            globalJS.gaSubmitEvent('LOGIN_COMPLETED');
            globalJS.fbSubmitEvent('LOGIN_COMPLETED');
        },
        error: function (err) {
            globalJS.displayError(err);
            globalJS.stopBtnLoader(btn);
        }
    });
}
